<template>
    <sidemenu-layout title="Restauants">
        <section class="container max-w-4xl mx-auto p-4 sm:p-12 md:p-20 h-full">
            <header class="hidden lg:flex justify-between items-center space-x-5 mb-10">
                <h1 class="text-2xl lg:text-4xl font-display font-bold">Search Restaurants</h1>
                <div class="hidden flex-none">
                    <button class="btn btn-primary" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>
            </header>
            
            <div class="pb-16">
                <div class="lg:text-2xl rounded-lg border border-solid border-cultured bg-white text-rich-black-fogra-29 relative">
                    <input type="text" class="w-full px-2 sm:px-4 py-2 rounded-lg focus:border-black-coral-lighter focus:ring-0 placeholder-silver-metallic overflow-hidden" placeholder="Search..." @focus="keywordFocus=true" @blur="keywordFocus=false" @input="debounceSearch($event.target.value)" :value="keyword" />
                    <div class="absolute right-0 top-0">
                        <button @click="clearKeyword()" :class="keyword.length == 0 && 'opacity-0'" type="button" class="z-50 bg-white rounded-lg m-3 sm:m-4 rounded text-xs font-sans font-medium text-gray-400 tracking-wide">CLEAR</button>
                    </div>
                    <div class="absolute left-0 top-12 w-full z-50">
                        <div v-if="suggestion.length > 0 && keyword !== selectedShopName" class="bg-white border-t border-solid border-cultured divide-y divide-solid shadow-lg rounded-b-lg">
                            <div v-for="s in suggestion" :key="s.shopID" class="w-full px-2 sm: px-4 py-2 cursor-pointer hover:bg-cultured" @click="selectRestaurant(s)">
                                {{s.name}}
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div v-if="loading" class="space-y-4 pt-4 pb-16">
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                </div>
                <div v-else class="space-y-4 pt-4 pb-16">
                    <order-pill v-for="o in orders" :key="o.orderID" :record="o"></order-pill>
                </div>
            </div>
        </section>
    </sidemenu-layout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import SidemenuLayout from '../layouts/SidemenuLayout.vue';
import OrderPill from '../components/OrderPill.vue'
import OrderPillSkeleton from '../components/OrderPillSkeleton.vue'
import fAPI from '../integration/foodexAPI'
import fUI from '../integration/foodexUI'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'Dashboard',
    components: {
        SidemenuLayout,
        OrderPill,
        OrderPillSkeleton,
    },
    setup() {
        const loading = ref(false)
        const keyword = ref('')
        const keywordFocus = ref(false)
        const suggestion = ref([])

        const selectedShopID = ref(0)
        const selectedShopName = ref('')
        const orders = ref([])
        
        let searchTimeout: any
        const debounceSearch = (val: any)  => {
            if(searchTimeout) clearTimeout(searchTimeout);

            searchTimeout = setTimeout(function() {
                keyword.value = val;
                if (val && val.length > 2)
                {
                    fAPI.searchRestaurants(val).then((response)=>{
                        console.log('getting this back', response.data)
                        suggestion.value = response.data
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                            fUI.toasty('Cannot load orders from server. Please try again')
                        }
                    })
                }
                else
                    suggestion.value = []
            }, 200);
        }

        const selectRestaurant = (s: any) => {
            selectedShopID.value = s.shopID
            selectedShopName.value = s.name
            keyword.value = s.name
            //trigger API to load order 
            loading.value = true
            fAPI.getRestaurantOrders(s.shopID).then((response)=>{
                console.log('getting this back', response.data)
                fUI.toasty(response.data.orders.length + ' orders loaded')
                orders.value = response.data.orders
            })
            .catch((authError: AxiosError) => {
                if (authError && authError.response)
                {
                    console.log('Operation failed', authError.response.status)
                    console.log('Error', authError.response)
                    fUI.toasty('Cannot load orders from server. Please try again')
                }
            })
            .finally(() => {
                loading.value = false
            })
        }

        const clearKeyword = () => {

            keyword.value = ''
            suggestion.value = []
            orders.value = []
        }

        return {
            loading,
            keyword,
            keywordFocus,
            suggestion,
            orders,
            selectedShopID,
            selectedShopName,
            debounceSearch,
            selectRestaurant,
            clearKeyword,
            
        }
    },
})
</script>