
import { defineComponent, ref } from 'vue';
import SidemenuLayout from '../layouts/SidemenuLayout.vue';
import OrderPill from '../components/OrderPill.vue'
import OrderPillSkeleton from '../components/OrderPillSkeleton.vue'
import fAPI from '../integration/foodexAPI'
import fUI from '../integration/foodexUI'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'Dashboard',
    components: {
        SidemenuLayout,
        OrderPill,
        OrderPillSkeleton,
    },
    setup() {
        const loading = ref(false)
        const keyword = ref('')
        const keywordFocus = ref(false)
        const suggestion = ref([])

        const selectedShopID = ref(0)
        const selectedShopName = ref('')
        const orders = ref([])
        
        let searchTimeout: any
        const debounceSearch = (val: any)  => {
            if(searchTimeout) clearTimeout(searchTimeout);

            searchTimeout = setTimeout(function() {
                keyword.value = val;
                if (val && val.length > 2)
                {
                    fAPI.searchRestaurants(val).then((response)=>{
                        console.log('getting this back', response.data)
                        suggestion.value = response.data
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                            fUI.toasty('Cannot load orders from server. Please try again')
                        }
                    })
                }
                else
                    suggestion.value = []
            }, 200);
        }

        const selectRestaurant = (s: any) => {
            selectedShopID.value = s.shopID
            selectedShopName.value = s.name
            keyword.value = s.name
            //trigger API to load order 
            loading.value = true
            fAPI.getRestaurantOrders(s.shopID).then((response)=>{
                console.log('getting this back', response.data)
                fUI.toasty(response.data.orders.length + ' orders loaded')
                orders.value = response.data.orders
            })
            .catch((authError: AxiosError) => {
                if (authError && authError.response)
                {
                    console.log('Operation failed', authError.response.status)
                    console.log('Error', authError.response)
                    fUI.toasty('Cannot load orders from server. Please try again')
                }
            })
            .finally(() => {
                loading.value = false
            })
        }

        const clearKeyword = () => {

            keyword.value = ''
            suggestion.value = []
            orders.value = []
        }

        return {
            loading,
            keyword,
            keywordFocus,
            suggestion,
            orders,
            selectedShopID,
            selectedShopName,
            debounceSearch,
            selectRestaurant,
            clearKeyword,
            
        }
    },
})
